import inputs from "./../data/inputs.json";
import narocilo from "./../data/narocilo.json";
import options from "./../data/options.json";

import HandleButton from "../component/HandleButton.js";
import SelectAnimal from "../component/SelectAnimal.js";
import InputComponent from "./../component/InputComponent.js";

import HandleOnFocus from "../component/HandleOnFocus.js";
import HandleOnBlur from "./../component/HandleOnBlur.js";
import HandleOnChange from "./../component/HandleOnChange.js";

import GetImage from "../component/GetImage.js";

import Modal from "../component/Modal.js";
import { formTest, modalData, service } from "./../component/Signals.js";
import { useState } from "react";

const url_back = process.env.REACT_APP_URL_BACK;

const Narocilo = () => {
  const [modal, setModal] = useState(false);
  const handleModal = () => {
    setModal((modal) => {
      return !modal;
    });
  };

  const HandleSubmit = async (event) => {
    event.preventDefault();

    // change object
    const narociloData = {
      storitev: service.value.narocilo_storitev,
      ime: service.value.narocilo_ime,
      email: service.value.narocilo_email,
      telefon: service.value.narocilo_telefon,
    };

    const response = fetch(`https://${url_back}/api/narocilo`, {
      mode: "cors",
      method: "POST",
      headers: { "Content-Type": "application/JSON" },
      body: JSON.stringify(narociloData),
    });

    const data = await response;

    if (data.ok) {
      const result = await data.json();
      modalData.value = {
        title: "naročilo",
        content: result.status === 200 ? narocilo[1].text : narocilo[2].text,
        button: "close",
      };
      handleModal();
    }

    // disable button when data is received
    formTest.value = false;
    event.target.reset();
    const select_box = document.getElementById(`narocilo_select_animal`);
    select_box.setAttribute("disabled", "disabled");

    // add disabled class to button
    const naroci_button = document.getElementById("naroci_button");
    naroci_button.classList.remove("button_enabled");
    naroci_button.classList.add("button_disabled");
    naroci_button.innerText = "vpiši podatke";
  };

  return (
    <div className="narocilo" id="narocilo">
      <div className="row">
        <div className="column_left">
          <div className="header">{narocilo[0].text}</div>
          <form className="form" id="narocilo_form" onSubmit={HandleSubmit}>
            <div className="container_storitev">
              <i className="fa-solid fa-wrench"></i>
              <label htmlFor="narocilo_storitev">storitev:</label>
              <div className="storitev_select">
                <select
                  className="input"
                  id="narocilo_storitev"
                  name="narocilo_storitev"
                  label="narocilo_storitev"
                  onChange={HandleOnChange}
                  onBlur={HandleOnBlur}
                >
                  <option id="0" value="servis">
                    izberi storitev
                  </option>
                  {options.map((option) => (
                    <option key={option.key} id={option.id} name={option.name} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {inputs.map((input) => (
              <InputComponent
                {...input}
                id={"narocilo_" + input.id}
                name={"narocilo_" + input.name}
                onFocus={HandleOnFocus}
                onChange={HandleOnChange}
                onBlur={HandleOnBlur}
              />
            ))}

            <SelectAnimal data="narocilo" />

            <HandleButton />
          </form>

          <Modal data={modal} handler={handleModal} />
        </div>

        <div className="column_right">
          <div className="image">
            <img src={GetImage("aleksa_1.jpg")} alt="servis klime" title="klima servis" />
          </div>

          <div className="inner_link">
            <a href="#pravno_obvestilo">pravno obvestilo: varovanje osebnih podatkov</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Narocilo;
