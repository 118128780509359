import data from "./../data/navigation.json";
const nav = data.slice(0, data.length - 1);

const NavigationDesktop = () => {
  return (
    <div className="navigation_desktop">
      {nav.map((row, n) => (
        <div key={n} className="link">
          <a key={row.key} href={`#${row.url}`}>
            {row.text}
          </a>
        </div>
      ))}
    </div>
  );
};

export default NavigationDesktop;
