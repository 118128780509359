import data from "./../data/pravno_obvestilo.json";

const PravnoObvestilo = () => {
  return (
    <div className="pravno_obvestilo" id="pravno_obvestilo">
      <div className="header">{data[0].text}</div>
      <div className="row">
        <div className="text">
          {data.map((row, n) => (
            <div key={n}>{row.id > 0 && row.text}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PravnoObvestilo;
