// page content
import Home from "./Home.js";
import Storitev from "./Storitev.js";
import Narocilo from "./Narocilo.js";
import About from "./About.js";
import Contact from "./Contact.js";
import PravnoObvestilo from "./PravnoObvestilo.js";

import Info from "./../component/Info.js";
import Navigation from "./../component/Navigation.js";
import links from "./../data/navigation.json";

const Content = () => {
  return (
    <div className="container">
      <div className="navigation_container">
        <Info />
        <Navigation />
      </div>

      <div className="content">
        {links.map((link) => (
          <section key={link.key} className={link.class} id={link.id}>
            {link.name === "home" && <Home />}
            {link.name === "storitev" && <Storitev />}
            {link.name === "narocilo" && <Narocilo />}
            {link.name === "about" && <About />}
            {link.name === "contact" && <Contact />}
            {link.name === "pravno_obvestilo" && <PravnoObvestilo />}
          </section>
        ))}
      </div>
    </div>
  );
};
export default Content;
