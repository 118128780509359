import ContactData from "./../component/ContactData";
import GetImage from "../component/GetImage.js";
import info from "./../data/info.json";

const Contact = () => {
  return (
    <div className="contact" id="contact">
      <div className="row">
        <div className="column_left">
          <div className="image">
            <img src={GetImage("aleksa_2.jpg")} alt="servis klime" title="klima servis" />
          </div>
        </div>
        <div className="column_right">
          <ContactData />
        </div>
      </div>
      <div className="row info_links">
        {info.map((row, n) => (
          <div key={n} className={row.class}>
            {/* eslint-disable-next-line */}
            <a key={row.key} href={row.url} target="_blank" rel="noopener">
              <i className={row.icon}></i>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contact;
