import RandomNumber from "../component/RandomNumber";
import animals from "./../data/animals.json";
import { animalTest, formTest, randomNumber } from "./Signals";

const SelectAnimal = (data) => {
  if (!formTest.value && randomNumber.value === undefined) {
    randomNumber.value = RandomNumber(animals.length);
  }

  const handleSelectedAnimal = (event) => {
    event.preventDefault();

    if (animalTest.value && randomNumber.value === Number(event.target.value)) {
      formTest.value = true;
      // remove disabled class to button
      const naroci_button = document.getElementById("naroci_button");
      naroci_button.classList.remove("button_disabled");
      naroci_button.classList.add("button_enabled");
      naroci_button.innerText = "pošlji naročilo"
      return;
    }
    const select_box = document.getElementById(`${data.data}_select_animal`);
    select_box.selectedIndex = 0;
    formTest.value = false;
  };

  return (
    <div className="container_animal">
      <i className={animals[randomNumber.value].icon}></i>
      <select
        onChange={handleSelectedAnimal}
        defaultValue={"0"}
        id={`${data.data}_select_animal`}
        disabled
      >
        <option id="0" value="0">
          izberi žival
        </option>
        {animals.map((animal) => (
          <option key={animal.key} id={animal.id} name={animal.name} value={animal.key}>
            {animal.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectAnimal;
