import { formTest, buttonNarocilo } from "./Signals";

const HandleButton = () => {
  return (
    <div className={buttonNarocilo.container_class_name}>
      <button
        className={`${buttonNarocilo.button_class_name} ${
          formTest.value === true ? buttonNarocilo.enabled_name : buttonNarocilo.disabled_name
        } `}
        id={buttonNarocilo.id}
      >
        {formTest.value === true
          ? `${buttonNarocilo.enabled_text}`
          : `${buttonNarocilo.disabled_text}`}
      </button>
    </div>
  );
};

export default HandleButton;
