import { useState } from "react";

import ToggleIcon from "./ToggleIcon";
import DropdownMenu from "./DropdownMenu";

const Info = () => {
  const [active, setActive] = useState(true);

  const handleChangeActive = () => {
    setActive((active) => {
      return !active;
    });
  };

  return (
    <div className="info">
      <div className="info_link">
        <a href="#home" id="home_link" className="home_link">
          home
        </a>
      </div>
      <div className="info_link">
        <i className="fa-solid fa-envelope"></i>
        <a href="#narocilo">info@tika.si</a>
      </div>
      <div className="phone">
        <i className="fa-solid fa-phone"></i>
        <a href="#contact">031 642 284</a>
      </div>

      <div className="navigation_mobile">
        <div className="nav-bar">
          <ToggleIcon active={active} handleChangeActive={handleChangeActive} />
        </div>
        <div className="drop-down">
          <DropdownMenu active={active} handleChangeActive={handleChangeActive} />
        </div>
      </div>
    </div>
  );
};

export default Info;
