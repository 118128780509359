import { ReactComponent as BarsIcon } from "./../icons/bars.svg";
import { ReactComponent as CloseIcon } from "./../icons/close.svg";

const ToggleIcon = ({ active, handleChangeActive }) => {
  return (
    <div className="toggle-wrapper">
      <i className="icon-button" onClick={() => handleChangeActive()}>
        {active ? <BarsIcon /> : <CloseIcon />}
      </i>
    </div>
  );
};

export default ToggleIcon;
