import contact from "./../data/contact.json";

const ContactData = () => {
  return (
    <div className="container_contact">
      {contact.map(
        (row, n) =>
          row.id > 0 &&
          row.id < 7 &&
          row.icon !== "" && (
            <div key={n} className="contact_row">
              <i className={row.icon}></i>
              <span>
                {row.text}
              </span>
            </div>
          )
      )}
    </div>
  );
};

export default ContactData;
