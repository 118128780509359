import data from "./../data/about.json";
import GetImage from "../component/GetImage.js";

const About = () => {
  return (
    <div className="about" id="about">      
      <div className="row">
        <div className="column_left">
        <div className="header">{data[0].text}</div>
          <div className="text">
            {data.map((row, n) => (
              <div key={n}>{row.id > 0 && row.text}</div>
            ))}
          </div>
        </div>
        <div className="column_right">
          <div className="image">
            <img src={GetImage("aleksa_5.jpg")} alt="servis klime" title="klima servis" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
