import data from "./../data/home.json";
import info from "./../data/info.json";
import GetImage from "../component/GetImage.js";

const Home = () => {
  return (
    <div className="home" id="home">
      <div className="row">
        <div className="column_left">
          <div className="header">{data[0].text}</div>
          <div className="image">
            <img src={GetImage("tika_main_logo.png")} alt="servis klime" title="klima servis" />
          </div>
        </div>
        <div className="column_right">
          <div className="text">
            {data.map((row, n) => (
              <div key={n}>{row.id > 0 && row.text}</div>
            ))}
          </div>
          <div className="image">
            <img src={GetImage("aleksa_3.jpg")} alt="servis klime" title="klima servis" />
          </div>
        </div>
      </div>
      <div className="row info_links">
        {info.map((row, n) => (
          <div key={n} className={row.class}>
            {/* eslint-disable-next-line */}
            <a key={row.key} href={row.url} target="_blank" rel="noopener">
              <i className={row.icon}></i>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
