import data from "./../data/navigation.json";
const nav = data.slice(0, data.length - 1);

const DropdownMenu = ({ active, handleChangeActive }) => {
  return (
    <>
      {!active && (
        <div className="drop-down-items">
          {nav.map((row, n) => (
            <div key={n} className="link">
              <a key={row.key} href={`#${row.url}`} onClick={() => handleChangeActive()}>
                {row.text}
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DropdownMenu;
