import { currentInput, showError } from "./Signals";

const InputComponent = (props) => {
  return (
    <div className={props.class}>
      <span className="icon">
        <i className={props.icon}></i>
      </span>

      <input
        id={props.id}
        name={props.name}
        type={props.type}
        autoComplete={props.autoComplete}
        required={props.required}
        autoFocus={props.autoFocus}
        title={props.title}
        min={props.min}
        max={props.max}
        onFocus={props.onFocus}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />

      <label htmlFor={props.id} className="label" id={`label_${props.id}`}>
        <span
          className={
            showError.value === true && currentInput.value === props.id
              ? "show_error"
              : "hide_error"
          }
        >
          {showError.value === true && currentInput.value === props.id
            ? props.error_message + props.title
            : props.label}
        </span>
      </label>
    </div>
  );
};

export default InputComponent;
