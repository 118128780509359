import NavigationDesktop from "./../component/NavigationDesktop";

const Navigation = () => {
  return (
    <div className="navigation">
      <NavigationDesktop />
    </div>
  );
};

export default Navigation;
