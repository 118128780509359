import { currentInput, showError } from "./Signals";

const HandleOnFocus = (event) => {
  event.preventDefault();
  const { id } = event.target;
  currentInput.value = id;
  showError.value = false;
};

export default HandleOnFocus;
