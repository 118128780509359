import { animalTest } from "./Signals";
import inputs from "./../data/inputs.json";

const HandleBlur = (event) => {
  event.preventDefault();
  const { id, title, value } = event.target;

  const namePattern = /^[A-ZČĆŠŽĐa-zčćšžđ]{3,15}$/;
  const emailPattern = /^[A-Za-z0-9._\\-]+@[A-Za-z0-9]+[.]+[A-Za-z]{2,3}$/;
  const phonePattern = /^[0-9]{9,10}$/;

  let valid = false;

  switch (true) {
    case id.includes("storitev"):
      valid = true;
      break;
    case id.includes("ime"):
      valid = namePattern.test(value);
      break;
    case id.includes("email"):
      valid = emailPattern.test(value);
      break;
    case id.includes("telefon"):
      valid = phonePattern.test(value);
      break;
    default:
      valid = false;
      break;
  }

  // check all form fileds are filled out
  if (id.includes("narocilo")) {
    const form = document.getElementById("narocilo_form");
    const children = form.querySelectorAll("select, input");
    const countAll = children.length;
    let countFull = 0;
    children.forEach((child) => {
      let val = document.getElementById(child.id).value;
      if (val && val.length > 0) {
        countFull++;
      }

      if (countFull === countAll - 1) {
        animalTest.value = true;
        const selectAnimal = document.getElementById("narocilo_select_animal");
        selectAnimal.removeAttribute("disabled");
      }
    });
  }

  if (!valid) {
    document.getElementById(id).focus();
    const label = document.getElementById(`label_${id}`);
    label.innerText = title;
    label.classList.add("show_error");
    return;
  }

  const labels = document.getElementsByClassName("label");
  [...labels].forEach((label, i) => {
    label.className = "label";
    label.innerText = inputs[i].label;
  });
};

export default HandleBlur;
