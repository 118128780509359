import { signal } from "@preact/signals-react";

const service = signal({
  narocilo_storitev: "servis",
  narocilo_ime: "",
  narocilo_email: "",
  narocilo_telefon: "",
});

// current input
const currentInput = signal();

// validation
const showError = signal(false);
const namePattern = signal(/^[A-ZČĆŠŽĐa-zčćšžđ]{3,15}$/);
const emailPattern = signal(/^[A-Za-z0-9._\\-]+@[A-Za-z0-9]+[.]+[A-Za-z]{2,3}$/);
const textareaPattern = signal(/[A-Za-z0-9,.@_-]$/);

const showModal = signal(false);
const modalData = signal({
  title: "modal title",
  content: "modal content",
  button: "close",
});

// random
const randomNumber = signal();

const letters = signal(0);
const maxLetters = signal(200);

const animalTest = signal(false);
const formTest = signal(false);

const buttonNarocilo = {
  container_class_name: "naroci_button",
  id: "naroci_button",
  button_class_name: "button",
  enabled_name: "button_enabled",
  disabled_name: "button_disabled",
  enabled_text: "pošlji naročilo",
  disabled_text: "vpiši podatke",
};

export {
  animalTest,
  buttonNarocilo,
  currentInput,
  emailPattern,
  formTest,
  letters,
  maxLetters,
  modalData,
  namePattern,
  randomNumber,
  service,
  showError,
  showModal,
  textareaPattern,
};
