import { service } from "./../component/Signals.js";

const HandleOnChange = (event) => {
  const { id, name, value } = event.target;

  if (id.includes("narocilo")) {
    service.value = { ...service.value, [name]: value };
  }
};

export default HandleOnChange;
