import { modalData } from "./Signals";

const Modal = ({ data, handler }) => {
  return (
    <div className={`modal ${data === true ? "modal_show" : "modal_hide"}`} id="modal">
      <div className="modal-title">
        {modalData.value.title}
        <i className="fa-regular fa-rectangle-xmark" onClick={() => handler(data)}></i>
      </div>
      <div className="modal-content">
        {modalData.value.content}
        {data.moda}
      </div>
      <div className="modal-footer">
        <button
          className={`button ${data === true ? "button_enabled" : "button_disabled"}`}
          onClick={() => handler(data)}
        >
          {modalData.value.button}
        </button>
      </div>
    </div>
  );
};

export default Modal;
